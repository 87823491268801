import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from 'react-router-dom';
import { FaSearch, FaTrash } from "react-icons/fa";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import swal from "sweetalert";
const ViewMaster = () => {
  const [data, setData] = useState([]);
  const location = useLocation();
  const { id, category_id } = location.state || {};
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Track if in edit mode
  const [name1, setName1] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null); // Store ID of item being edited

  useEffect(() => {
    fetchMenuData();
  }, []);

  const fetchMenuData = () => {
    setLoading(true);
    axios.get(`https://mahalakshmimobiles.com/admin/get_menu_list.php?spec_id=${id}`)
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const handleAddOrUpdateItem = () => {
    const apiUrl = isEditing
      ? `https://mahalakshmimobiles.com/admin/get_menu_list.php?id=${selectedItemId}&&name=${name1}&&category_id=${category_id}&&spec_category_id=${id}&&type=UPDATE`
      : `https://mahalakshmimobiles.com/admin/get_menu_list.php?name=${name1}&&category_id=${category_id}&&spec_category_id=${id}`;
  
    axios
      .get(apiUrl)
      .then((response) => {
        swal({
          title: isEditing ? "Item Updated" : "Item Added",
          text: isEditing ? "Item updated successfully!" : "Item added successfully!",
          icon: "success",
          button: "OK",
        });
        setShowModal(false);
        setName1('');
        fetchMenuData(); // Refresh data
      })
      .catch((error) => {
        console.error(isEditing ? "Error updating item:" : "Error adding item:", error);
        swal({
          title: isEditing ? "Update Failed" : "Addition Failed",
          text: isEditing ? "Failed to update item." : "Failed to add item.",
          icon: "error",
          button: "OK",
        });
      });
  };

  const handleEdit = (item) => {
    setName1(item.menu_name); // Set existing value in input
    setSelectedItemId(item.id); // Store the item ID
    setIsEditing(true); // Set editing mode
    setShowModal(true); // Open modal
  };

  const handleDelete = (itemId) => {
    swal({
      title: "Are you sure?",
      text: "Do you really want to delete this item?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .get(`https://mahalakshmimobiles.com/admin/get_menu_list.php?id=${itemId}&&type=DELETE`)
          .then((response) => {
            swal("Item deleted successfully!", {
              icon: "success",
            });
            fetchMenuData();
          })
          .catch((error) => {
            console.error("Error deleting item:", error);
            swal("Failed to delete item.", {
              icon: "error",
            });
          });
      }
    });
  };

  const handleSortByColumn = (column) => {
    const order = sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(order);
    setData([...data].sort((a, b) => {
      if (order === "asc") return a[column] > b[column] ? 1 : -1;
      else return a[column] < b[column] ? 1 : -1;
    }));
  };

  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleShowModal = () => {
    setIsEditing(false); // Reset editing mode
    setName1(""); // Clear input
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);
  return (
    <div className="main-container mt-3">
      <div className="flex-container pt-5">
        <h3 className="head-fon-mv">Menu List</h3>
        <div className="breadcrumb-header float-end">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
              <li className="breadcrumb-item active" aria-current="page">Menu List</li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="card-cat mt-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <label className="me-2">Show</label>
            <select
              style={{ height: "35px" }}
              className="form-select"
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
            >
              {[20, 40, 60, 80].map((num) => (
                <option key={num} value={num}>{num}</option>
              ))}
            </select>
          </div>

          <div className="d-flex align-items-center">
            <input
              type="text"
              className="form-control me-3"
              placeholder="Search"
              onChange={(e) => /* implement search functionality here */ { }}
            />
            <button className="btn btn-primary" onClick={handleShowModal}>
              Add New Item
            </button>
          </div>
        </div>

        <div className="table-container">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <table className="table text-center table-striped table-bordered">
              <thead className="table-light">
                <tr>
                  <th onClick={() => handleSortByColumn("id")} style={{ cursor: "pointer" }}>
                    S.No {sortColumn === "id" && (sortOrder === "asc" ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />)}
                  </th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.length > 0 ? (
                  paginatedData.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.menu_name}</td>
                      <td>{item.category_name}</td>
                      <td style={{ textAlign: "center" }}>
                        <button  className="btn-edit" onClick={() => handleEdit(item)}>
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                        <button  className="btn-delete" onClick={() => handleDelete(item.id)}>
                        <FaTrash />
                        </button>
                      </td>

                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No records found</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>

        <div className="pagination-container mt-3">
          <button  style={{width:150,justifyContent:'flex-end'}}className="btn btn-secondary me-2" onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)} disabled={currentPage === 1}>
            Previous
          </button>
          <button  style={{width:150,justifyContent:'flex-start'}} className="btn btn-secondary" onClick={() => setCurrentPage(currentPage < Math.ceil(data.length / itemsPerPage) ? currentPage + 1 : currentPage)} disabled={currentPage === Math.ceil(data.length / itemsPerPage)}>
            Next
          </button>
        </div>

        {/* Modal Structure */}

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? "Edit Menu Item" : "Add New Menu Item"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              className="form-control mb-2"
              placeholder="Enter Item Name"
              value={name1}
              onChange={(e) => setName1(e.target.value)}
            />
            {/* Other form fields can go here */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleAddOrUpdateItem}>
              {isEditing ? "Update" : "Save"}
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ViewMaster;
