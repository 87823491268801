import swal from "sweetalert";
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
function AutoMaster() {
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const { id } = location.state || {};
    const Navigate = useNavigate();
    const [parentMaster, setParentMaster] = useState('');
    const [masterName, setMasterName] = useState('');
    const [masters, setMasters] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [editMasterId, setEditMasterId] = useState(null);
    const [loading, setLoading] = useState(false);
    const parentMasterInputRef = useRef(null);

    const fixedCategoryId = '5';

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
        resetForm();
    };

    useEffect(() => {
        if (id) {
            fetchMasters(id);
        }
    }, [id]);
    const fetchMasters = (categoryId) => {
        setLoading(true);
        console.log('Fetching data for categoryId:', categoryId);
        axios.get('https://mahalakshmimobiles.com/admin/get_specification_category.php')
            .then(response => {
                console.log('API response:', response);
                // Filter the data to include only items with the specified category_id
                const filteredData = response.data.filter(item => item.category_id === categoryId);
                setMasters(filteredData);
                if (filteredData.length > 0) {
                    setParentMaster(filteredData[0].category_name); // Set category_name as the parent master
                    console.log('parentMaster updated to:', filteredData[0].category_name);
                } else {
                    setParentMaster(''); // Fallback if no data is found
                    console.log('No masters found for the given category');
                }
            })
            .catch(error => console.error('Error fetching data:', error))
            .finally(() => setLoading(false));
    };


    const resetForm = () => {

        setMasterName('');
        setEditIndex(null);
        setEditMasterId(null);
    };

    const handleInsert = () => {
        if (masterName) {
            if (editIndex !== null) {
                axios.get('https://mahalakshmimobiles.com/admin/get_specification_category.php', {
                    params: {
                        id: editMasterId,
                        name: masterName,
                        category_id: id,
                        type: 'UPDATE',
                    }
                })
                    .then((res) => {
                        fetchMasters(id);
                        handleCloseModal();
                        swal("Updated!", "Master has been updated successfully.", "success");
                    })
                    .catch(error => console.error('Error updating master:', error));
            } else {
                axios.get('https://mahalakshmimobiles.com/admin/get_specification_category.php', {
                    params: {
                        name: masterName,
                        category_id: id,
                    }
                })
                    .then((res) => {

                        handleCloseModal();
                        swal("Added!", "Master has been added successfully.", "success");
                        fetchMasters(id);
                    })
                    .catch(error => console.error('Error adding master:', error));
            }
        }
    };

    const handleEdit = (index) => {
        const masterToEdit = masters[index];
        // setParentMaster(masterToEdit.parentMaster);
        setMasterName(masterToEdit.master_name);
        setEditIndex(index);
        setEditMasterId(masterToEdit.id);
        handleOpenModal();
    };
    const handleOpen = (master) => {
        const id = master.id
        const category_id = master.category_id
        Navigate('/ViewMaster', { state: { id, category_id } })
        console.log(master)
    }
    const handleDelete = (index) => {
        const masterToDelete = masters[index];

        // Confirm before delete
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this master!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    axios.get('https://mahalakshmimobiles.com/admin/get_specification_category.php', {
                        params: {
                            id: masterToDelete.id,
                            type: 'DELETE',
                        }
                    })
                        .then((res) => {
                            fetchMasters(id);
                            swal("Deleted!", "Master has been deleted successfully.", "success");
                        })
                        .catch(error => console.error('Error deleting master:', error));
                }
            });
    };
    useEffect(() => {
        console.log('Updated parentMaster:', parentMaster);
    }, [parentMaster]); // This will run whenever parentMaster changes
    return (
        <div className="main-container mt-3">
            <h3 className="head-fon-mv">Masters</h3>

            <Button variant="primary" onClick={handleOpenModal} className="add-category-button" style={{width:150}}>
                Add Master
            </Button>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editIndex !== null ? 'Edit Master' : 'Add Master'}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <label className="bold">
                            Parent Master <i className="text-danger">*</i>
                        </label>
                        <input
                            className="form-control"
                            placeholder="Enter Parent Master"
                            value={parentMaster}
                            readOnly // Makes the input non-editable
                        />

                        <label className="bold mt-3">
                            Master Name <i className="text-danger">*</i>
                        </label>
                        <input
                            className="form-control"
                            placeholder="Enter Master Name"
                            value={masterName}
                            onChange={(e) => setMasterName(e.target.value)}
                        />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="success" onClick={handleInsert}>
                        {editIndex !== null ? 'Update' : 'Save'}
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <div>


                {loading ? (
                    <div className="d-flex justify-content-center mt-4">
                        <Lottie
                            height={250}
                            width={250}
                            options={{
                                loop: true,
                                animationData: require("../Assets/Animation - 1716197548567.json"),
                                autoplay: true,
                            }}
                        />
                    </div>
                ) : (
                    <div className="mt-4">
                        <h5 className="mb-3">Added Masters</h5>
                        {masters.length === 0 ? (
                            <p className="text-center text-muted"> Currently no master for this category.</p>
                        ) : (
                            <div className="row">
                                {masters.map((master, index) => (
                                    <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                                        <div className="p-3 border rounded shadow-sm bg-white text-center">
                                            <p className="text-muted">{master.master_name}</p>
                                            <div className="d-flex justify-content-center mt-2">
                                                <button className="btn btn-outline-success btn-sm mx-1" onClick={() => handleOpen(master)}>Open</button>
                                                <button className="btn btn-outline-primary btn-sm mx-1" onClick={() => handleEdit(index)}>
                                                    Edit
                                                </button>
                                                <button className="btn btn-outline-danger btn-sm mx-1" onClick={() => handleDelete(index)}>
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}

            </div>
        </div>
    );
}

export default AutoMaster;
